.data-list {
  width: 100%;

  &--small-key {
    .data-list__key {
      flex: 2;
    }
    .data-list__value {
      flex: 3;
    }
  }
  &--tiny-key {
    .data-list__key {
      flex: 1;
    }
    .data-list__value {
      flex: 4;
    }
  }
  &__icon {
    flex-shrink: 1;
    padding: 0 0.5rem;
    color: var(--gray-500);
  }

  &__row {
    display: flex;
    flex-direction: row;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    gap: 1rem;
  }
  &__block {
    display: flex;
    flex-direction: column;
    line-height: 1.5rem;
  }
  &__key,
  &__value {
    flex: 1;
  }
  &__key,
  &__value--style-as-key {
    color: var(--gray-600);
  }
  &__value {
    word-break: break-word;
    &:empty::after,
    > *:first-child:empty::after {
      content: '-';
      color: var(--gray-500);
    }

    &--empty::after {
      content: '-';
      color: var(--gray-500);
    }
  }
}
