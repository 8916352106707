.split-pill,
.clickable-pill {
  max-width: 100%;
  &__button {
    background-color: transparent;
    border: 1px solid var(--primaryColorAlt);
    border-left: 1px solid transparent;
    color: var(--primaryColor);
    margin: 0;
    border-radius: 0;
    padding: 0.25rem 0.5rem;
    &:first-child {
      border-left: 1px solid var(--primaryColorAlt);
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
    &:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
    &:hover {
      background-color: var(--primaryColorWashed);
      color: var(--primaryColor);
      border-left: 1px solid var(--primaryColorAlt);
    }
    > fa-icon {
      color: var(--gray-600);
    }
  }
  &__display {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    &__text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      flex: 1;
    }
  }
  &__action {
    width: 2rem;
    fa-icon {
      color: var(--primaryColor);
    }
  }
  &--small {
    button {
      padding: 0.25rem;
      line-height: 1;
      font-size: 0.75rem;
      border-radius: 0;
      &:first-child:not(:disabled) {
        border-left: 1px solid var(--primaryColorAlt);
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
      &:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
  }
}
