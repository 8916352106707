nav.nav__main-nav {
  height: var(--nav-bar-height);
  .icon-btn {
    display: inline-block;
    width: var(--nav-bar-button-width);
    height: var(--nav-bar-button-height);

    &.btn {
      background-color: transparent;
      border-radius: 0;
      color: var(--gray-600);
      i {
        font-size: 1.5rem;
      }
    }

    &:hover {
      background-color: var(--headerBorderColor);
    }

    img {
      height: var(--nav-bar-height);
      width: 100%;
      object-fit: contain;
      display: block;
    }
  }
}
