table,
.table {
  margin-bottom: 0;

  &--striped {
    tbody tr:nth-child(even) {
      background-color: var(--gray-100);
    }
  }

  &--bordered,
  &-bordered {
    border: 1px solid var(--gray-300);
  }

  tr {
    &.danger {
      background-color: var(--dangerColorWashed);

      > td:first-child {
        border-left: 3px solid var(--dangerColor);
        padding-left: calc(1.5rem - 2px);
      }
    }

    &.success {
      background-color: var(--successColorWashed);
    }

    &.warning {
      background-color: var(--warningColorWashed);

      > td:first-child {
        border-left: 3px solid var(--warningColor);
        padding-left: calc(1.5rem - 2px);
      }
    }

    &.active {
      > td:first-child {
        border-left: 3px solid var(--primaryColor);
        padding-left: calc(1.5rem - 2px);
      }
    }

    &.active td {
      color: var(--gray-800);
      background-color: var(--primaryColorWashed);
    }

    &.expanded {
      > td:first-child {
        border-left: 3px solid var(--primaryColor);
        padding-left: calc(1.5rem - 2px);
      }
    }

    &.disabled {
      td,
      &:hover td,
      &.active {
        border-left: none;
        color: var(--gray-600);
        background-color: transparent;
        cursor: not-allowed;
      }

      td:first-child {
        padding-left: 1.5rem;
      }
    }

    &.summary-row th,
    &.summary-row td {
      color: var(--gray-800);
      background-color: var(--gray-100);
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      font-weight: 500;
    }

    &.section-row {
      td {
        background-color: var(--gray-400);
        color: var(--gray-800);
        font-weight: 500;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
      }

      &--small td {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }

      &--large td {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
      }
    }

    th {
      padding: 1rem 0.5rem;
      font-size: 0.875rem;
      color: var(--gray-600);
      font-weight: 500;
      border-top: 0;
      border-bottom: 1px solid var(--gray-300);
      vertical-align: top;
      white-space: nowrap;

      &:first-child {
        padding-left: 1.5rem;
      }

      &:last-child {
        padding-right: 1.5rem;
      }
    }

    td {
      padding: 0.75rem 0.5rem;

      &:first-child {
        padding-left: 1.5rem;
      }

      &:last-child {
        padding-right: 1.5rem;
      }

      &.cell-link:has(> a) {
        padding: 0;

        &:first-child {
          padding-left: 0;

          > a {
            padding-left: 1.5rem;
          }
        }

        &:last-child {
          padding-right: 0;
        }

        > a {
          padding: 0.75rem 0.5rem;

          &:link,
          &:visited {
            color: inherit;
          }

          &:last-child {
            padding-right: 1.5rem;
          }
        }
      }

      font-size: 0.875rem;
      vertical-align: top !important;
      border-top: 0;
      border-bottom: 1px solid var(--gray-300);

      &.cdk-column-actions,
      &.actions-column {
        // = font-size * line-height divided by 2, plus the column padding - half of the btn-size
        padding-bottom: calc((0.875rem * 1.5 / 2) + 0.75rem - 1rem);
        padding-top: calc((0.875rem * 1.5 / 2) + 0.75rem - 1rem);

        &.cell-link:has(> a) {
          padding-bottom: 0;
          padding-top: 0;

          > a {
            // = font-size * line-height divided by 2, plus the column padding - half of the btn-size
            padding-bottom: calc((0.875rem * 1.5 / 2) + 0.75rem - 1rem);
            padding-top: calc((0.875rem * 1.5 / 2) + 0.75rem - 1rem);
          }
        }
      }

      &[rowspan] {
        vertical-align: top !important;
        background-color: var(--white);
        border-right: 1px solid var(--gray-300);
      }
    }

    td,
    th {
      &.no-stretch {
        width: 1%;
      }
    }
  }

  // when a cell has a direct link, we "blow up" the link so the entire cell becomes clickable, this involves
  // - setting the link's height to 100% (which has only effect if its parent has a height)
  // - moving the paddings from the cell to the link (see below)
  td.cell-link:has(> a) {
    height: 1px;

    > a {
      height: 100%;
      display: block;
    }
  }

  &--small {
    tr {
      td {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        &.cell-link:has(> a) {
          padding-top: 0;
          padding-bottom: 0;

          > a {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
          }
        }

        &.cdk-column-actions,
        &.actions-column {
          padding-bottom: calc((0.875rem * 1.5 / 2) + 0.5rem - 0.75rem);
          padding-top: calc((0.875rem * 1.5 / 2) + 0.5rem - 0.75rem);

          &.cell-link:has(> a) {
            padding-bottom: 0;
            padding-top: 0;

            > a {
              padding-bottom: calc((0.875rem * 1.5 / 2) + 0.5rem - 0.75rem);
              padding-top: calc((0.875rem * 1.5 / 2) + 0.5rem - 0.75rem);
            }
          }

          nxh-icon-button > .btn,
          .btn-icon {
            height: 1.5rem;
          }
        }
      }
    }
  }

  &--large {
    tr {
      td {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        &.cell-link:has(> a) {
          padding-top: 0;
          padding-bottom: 0;

          > a {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
          }
        }

        &.cdk-column-actions,
        &.actions-column {
          padding-bottom: calc((0.875rem * 1.5 / 2) + 1.5rem - 1rem);
          padding-top: calc((0.875rem * 1.5 / 2) + 1.5rem - 1rem);

          &.cell-link:has(> a) {
            padding-bottom: 0;
            padding-top: 0;

            > a {
              padding-bottom: calc((0.875rem * 1.5 / 2) + 1.5rem - 1rem);
              padding-top: calc((0.875rem * 1.5 / 2) + 1.5rem - 1rem);
            }
          }
        }
      }
    }
  }

  &--hoverable {
    tr {
      &:hover td:not([rowspan]) {
        background-color: var(--primaryColorWashed);
      }
    }
  }

  &--clickable {
    tr {
      cursor: pointer;

      th {
        cursor: auto;
      }

      &:hover td:not([rowspan]) {
        background-color: var(--primaryColorWashed);
      }
    }
  }

  &--rowspan {
    tr {
      td:not([rowspan]):first-of-type {
        padding-left: 0.5rem;
      }
    }
  }

  tr.danger {
    td {
      background-color: var(--dangerColorWashed);
    }

    &:hover td {
      background-color: var(--dangerColorWashedHover);
    }

    &.detail-row {
      td {
        cursor: default;
        background-color: var(--dangerColorLightWashed);
      }

      &:hover td {
        background-color: var(--dangerColorLightWashed);
      }
    }
  }

  tr.summary-row {
    &:hover td {
      background-color: transparent;
      cursor: default;
    }
  }
}

//unfortunately CdkRowDefWhen does not work on CdkHeaderRow, so we have to use css to hide the total headers when there is no data
// use this in combination with hide-summary-rows-when-empty.directive
.hide-summary-rows {
  .summary-row {
    display: none;
  }
}

.check-circle {
  color: var(--successColor);
}

.times-circle {
  color: var(--dangerColor);
}

.exclamation-circle {
  color: var(--warningColor);
}

.checkbox-table {
  position: relative;

  .table-actions {
    position: absolute;
    top: 0;
    left: 50px;
    right: 0;
    background: white;
    padding: 0.5rem;
  }
}

// can be removed later on after all columns with class 'actions' have been removed
th.actions {
  text-align: right;

  .icons {
    display: flex;
    justify-content: flex-end;
  }
}

.cdk-column-actions {
  text-align: right;

  //use this if the actions column contains multiple action buttons
  > div.actions-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.25rem;
  }
}

//GROUPED TABLE
.group-table {
  background: var(--white);
  border-radius: var(--cardBorderRadius);
  // TODO FEG-1240 stijn: deze mogen denk ik weg, lijkt op een workaround voor de row class die margin toevoegt
  // padding-right: 1rem;
  // padding-left: 1rem;
  table-layout: fixed;
  width: 100%;

  &__header {
    display: flex;
    border-bottom: 2px solid var(--gray-300);
    color: var(--gray-600);
    font-weight: 500;
    align-items: flex-start;
    flex-wrap: nowrap;

    &__inner {
      width: calc(100% - 13rem);
      display: flex;
      position: relative;
      @media screen and (max-width: 1280px) {
        width: calc(100% - 10rem);
      }
    }

    &__dates {
      width: calc(100% - 6rem);
      margin-left: 3rem;
      display: flex;
    }
  }

  &__description {
    padding: 1rem 0.75rem;
    width: 13rem;
    line-height: 1;
    background: var(--gray-100);
    word-break: break-word;
    border-right: 1px solid var(--gray-300);
    @media screen and (max-width: 1280px) {
      width: 10rem;
    }

    &__unit {
      float: right;
    }
  }

  &__parameter {
    display: flex;
    border-bottom: 1px solid var(--gray-400);
    flex-wrap: nowrap;

    .group-table__description {
      padding: 0.5rem 0.75rem;

      &__label {
        margin-bottom: 0.5rem;
        line-height: 1.2;

        button {
          padding: 0;
          text-align: left;
        }
      }

      &__latest {
        font-size: 0.75rem;

        &--info {
          color: var(--gray-600);
          display: flex;
          justify-content: space-between;
          font-size: 0.7rem;
          line-height: 1.125rem;

          fa-icon {
            margin-right: 0.25rem;
          }
        }

        &__value {
          padding-left: 0.125rem;
          font-weight: 500;

          &__red {
            color: var(--dangerColor);
          }
        }
      }
    }

    &__inner {
      width: calc(100% - 13rem);
      display: flex;
      @media screen and (max-width: 1280px) {
        width: calc(100% - 10rem);
      }
    }
  }

  &__paging {
    width: 3rem;
    padding: 0 !important;
    text-align: center;
    position: absolute;
    top: 0.35rem;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }

    .btn:disabled {
      opacity: 0.35;
      cursor: not-allowed;
    }
  }

  &__date {
    padding: 1rem;
    width: calc(100% / 7);
    word-break: break-word;
    line-height: 1;
    text-align: left;

    &--last {
      background: var(--warningColorWashed);
    }
  }

  &__datas {
    width: calc(100% - 6rem);
    margin-left: 3rem;
    display: flex;
  }

  &__data {
    padding: 0.5rem 1rem;
    width: calc(100% / 7);
    align-items: flex-start;
    display: flex;
    justify-content: center;
    flex-direction: column;

    > div {
      padding: 0.25rem 0;
    }

    &__time {
      font-size: 0.625rem;
      color: var(--gray-600);
      margin-right: 0.5rem;
      line-height: 1;
    }

    &__attribute {
      margin-right: 0;
      line-height: 1;
    }

    &__extra {
      margin-left: 0.5rem;
      line-height: 1;
      color: var(--gray-600);

      &:hover {
        color: var(--primaryColor);
      }
    }

    &__tooltip-title {
      font-size: 0.875rem;
    }

    &__tooltip-body {
      font-size: 0.8125rem;
    }

    &--last {
      background: var(--warningColorWashed);
    }

    &__value {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      justify-content: center;
      padding: 0;

      &--top {
        justify-content: flex-start;
      }

      &--bottom {
        justify-content: flex-end;
      }

      &:hover {
        button {
          display: inline-block;
        }
      }

      fa-icon {
        margin-left: 0.25rem;
      }

      &__red {
        color: var(--dangerColor);
      }

      button {
        position: absolute;
        line-height: 1;
        color: var(--primaryColor);
        right: 0;
        padding: 0;
        display: none;
      }
    }
  }

  &--5cols {
    .group-table {
      &__data,
      &__date {
        width: calc(100% / 5);
      }
    }
  }
}

.table-is-loading {
  min-height: 15rem;
}

.table-errorstate {
  min-height: 20rem;
}

@media screen and (max-width: 1280px) {
  .vital-parameter-right-container {
    .group-table__description {
      width: 7rem;
    }

    .group-table__parameter__inner,
    .group-table__header__inner {
      width: calc(100% - 7rem);
    }
  }
}

tr.nxh-disabled {
  td,
  &:hover td,
  &.active td {
    cursor: not-allowed;
    color: var(--gray-500);

    * {
      // note pointer-events:none only on child nodes, impossible to get cursor correct while setting pointer events to none
      // disabling pointer-events on the root is done via javascript
      pointer-events: none;
    }

    a {
      color: var(--gray-500);
    }
  }
}

td.nxh-disabled {
  cursor: not-allowed;
  color: var(--gray-500);

  * {
    pointer-events: none;
  }

  a {
    color: var(--gray-500);
  }
}

th.nxh-align-right + th.nxh-align-left,
td.nxh-align-right + td.nxh-align-left,
th.nxh-align-right + th:not(.nxh-align-right):not(.nxh-align-center),
td.nxh-align-right + td:not(.nxh-align-right):not(.nxh-align-center) {
  padding-left: 1.5rem; // = default 0.5 + 1 rem extra
}

tr.sticker-target:has(.sticker--top-left),
tr.sticker-target:has(.sticker--top-right),
tr.sticker-target:has(.sticker--bottom-right),
tr.sticker-target:has(.sticker--bottom-left) {
  position: relative;
}
