@mixin firefox-only {
  @at-root {
    @-moz-document url-prefix() {
      & {
        @content;
      }
    }
  }
}

h1,
.page-title,
.breadcrumb__item--span,
.breadcrumb__item--link,
.top-level .tabnav .tabnav_items-container li a,
.btn,
.btn * {
  @include firefox-only {
    white-space: nowrap !important;
  }
}

input[type='time'] {
  @include firefox-only {
    background: none !important;
  }
}
