.sidebar-widget {
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: 1px solid var(--gray-400);

  &:last-child {
    border-bottom: none;
  }

  &__header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;

    button.btn-link {
      font-size: 0.75rem;
    }

    &__controls {
      display: flex;
      flex-shrink: 0;
      flex-wrap: wrap;
    }
  }

  &__title {
    font-size: 1rem;
    flex-grow: 1;
    color: var(--gray-700);
  }

  &__body {
    margin-top: 0.5rem;
    min-height: 1rem;
  }

  &__item {
    margin-bottom: 0.5rem;
  }

  button.btn-collapse {
    border: none;
    padding-left: 0.25rem;
    margin-right: -0.5rem;

    &:hover {
      color: var(--primaryColor);
    }
  }

  &__footer {
    padding: 1rem 0;
    display: flex;
    align-items: center;

    &__controls {
      display: flex;
      justify-content: flex-end;
      flex: 1;
    }
  }
}

.sidebar-card {
  padding: 1rem 0;
  border-bottom: 1px solid var(--gray-400);

  &:last-child {
    border-bottom: none;
  }

  &:first-child {
    padding-top: 0;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: -0.375rem;
    line-height: 1rem;

    &__title {
      color: var(--gray-600);
      font-size: 0.875rem;
      font-weight: 500;
    }
    &__controls {
      flex-shrink: 1;
      display: flex;
      align-items: center;

      & > * {
        margin: 0 0 0 1rem;
      }
    }
  }
  &-body {
    color: var(--bodyTextColor);
    font-size: 0.875rem;
    margin-top: 0.5rem;
    line-height: 1rem;
  }
  &-footer {
    display: flex;
    align-items: baseline;
    color: var(--gray-600);
    font-size: 0.75rem;
    margin-top: 0.25rem;
    line-height: 1.125rem;

    & > * {
      margin: 0 0.25rem 0 0;
    }
  }
}
