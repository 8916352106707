ngb-datepicker,
ngb-datepicker.mini-calendar,
.mini-calendar__button {
  border: 0 !important;

  .ngb-dp-header {
    background-color: transparent;
  }

  .ngb-dp-navigation-chevron {
    color: var(--gray-600);
    //default is .2em
    border-width: 0.25em 0.25em 0 0;
  }

  .ngb-dp-month-name {
    font-size: 0.875rem;
    color: var(--gray-600);
    font-weight: bold;
    line-height: 1rem;
    height: auto;
    padding-top: 4px;
    background-color: transparent;
  }

  .ngb-dp-weekdays {
    background-color: transparent;
    border-bottom: 0;

    .ngb-dp-weekday.small {
      color: var(--gray-600);
      display: block;
      visibility: hidden;
      padding-left: 0.4rem;
      font-style: normal;

      &:first-letter {
        visibility: visible;
      }
    }
  }
}

ngb-datepicker.dropdown-menu.mini-calendar__button {
  padding: 0.5rem;
}
