.hero-title {
  font-size: 2rem;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 0.05rem;
  color: var(--gray-800);
}

.page-title {
  color: var(--gray-800);
  font-size: 1.25rem;
  line-height: 1;
  font-weight: 400;
}

.sub-title {
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--gray-700);
}

.modal-title {
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--gray-700);
}

.card-title {
  font-size: 1rem;
  font-weight: 500;
  color: var(--gray-700);
}

.small-title {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--gray-600);
  margin-bottom: 1rem;

  &.underline {
    border-bottom: 1px solid var(--gray-400);
    margin-bottom: 1rem;
  }
}

.form-subtitle,
.subtitle {
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--gray-700);
  margin-bottom: 0.5rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid var(--gray-400);
}

.heavy-text {
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--gray-700);
  margin-bottom: 0.5rem;
}

.label-on-data {
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--gray-600);
}

.body-text {
  font-size: 0.875rem;
  color: var(--gray-800);
}

.label-on-form {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--gray-600);
}

.mask-on-form {
  font-size: 0.75rem;
  color: var(--gray-500);
}

.text-link {
  font-size: 0.875rem;
  color: var(--primaryColor);
}

.section__title {
  font-weight: 500;
  color: var(--gray-700);
}

.text-subtle {
  color: var(--gray-600);
  font-size: 0.875rem;

  &--small {
    color: var(--gray-600);
    font-size: 0.75rem;
  }
}

.text {
  &-primary {
    color: var(--primaryColor) !important;
  }

  &-secondary {
    color: var(--secondaryColor) !important;
  }

  &-success {
    color: var(--successColor) !important;
  }

  &-warning {
    color: var(--warningColor) !important;
  }

  &-danger {
    color: var(--dangerColor) !important;
  }

  &-disabled {
    color: var(--disabledColor) !important;
  }
}

.nxh-input-label {
  color: var(--formLabelColor);
  font-size: 0.75rem;
}
