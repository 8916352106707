// see also _custom-variables

.nav-tabs {
  border-bottom: none;

  .nav-link {
    color: opacify(#0c1827, 0.5);
    font-weight: 500;
    padding-left: 0;
    padding-right: 0;
    margin-left: $nav-link-padding-y;
    margin-right: $nav-link-padding-y;
  }

  li.nav-item:first-of-type {
    margin-left: 0;
  }

  .nav-item {
    margin-bottom: -2px;
  }

  .nav-link:focus,
  .nav-link:hover {
    border-color: transparent;
    cursor: pointer;
  }

  .nav-link.active::after,
  .nav-item.show .nav-link::after {
    content: '__';
    display: block;
    background-color: var(--primaryColor);
    height: 0.2rem;
    color: transparent;
    margin-top: 0.5rem;
  }
}
