:not(.nxh-card-component, .nxh-split-card-component).card {
  box-shadow: var(--cardBoxShadow);
  margin: 0 0 1rem;
  width: 100%;
  padding: 0;

  &:last-child {
    margin: 0;
  }

  hr {
    border-color: var(--gray-300);
  }

  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 1.5rem 1.5rem 0;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 0;
    background: var(--cardBGColor);

    &--large {
      padding: 1.5rem;
    }

    &--dark {
      background: var(--gray-100);
    }

    &--striped {
      border-bottom: 1px solid var(--gray-300);
    }

    &__title {
      flex-grow: 1;
      line-height: 1;
    }

    &__controls {
      flex-shrink: 1;
      margin-top: auto;
      margin-bottom: auto;

      > * {
        margin: 0 0.25rem 0 0;
        line-height: 1;
      }
    }
  }

  &-body {
    padding: 1.5rem;

    &--striped {
      border-bottom: 1px solid var(--gray-300);

      &:last-child {
        border-bottom: 0;
      }

      .card-body__container {
        border-bottom: 1px solid var(--gray-300);
        padding-bottom: 0.5rem;
        &:last-child {
          border: 0;
          padding-bottom: 0;
        }
      }
    }

    &__container {
      margin: 0 0 1rem;

      &:last-child {
        margin: 0;
      }

      > * {
        margin: 0 0 0.5rem;

        &:last-child {
          margin: 0;
        }
      }

      &--has-icon {
        display: flex;

        > fa-icon,
        > fa-layers {
          color: var(--gray-500);
          margin-right: 1rem;
          font-size: 1rem;
          width: 1.5rem;
          text-align: center;
          line-height: 1;
        }
      }

      &--centered {
        text-align: center;
      }

      &__content {
        margin: 0;
        flex: 1;

        > * {
          margin: 0 0 0.5rem;

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    &--no-padding {
      padding: 0;
    }
  }

  &-body ~ &-footer {
    border-top: 1px solid var(--gray-300);
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0.75rem 1.5rem;
    border-top: 0;
    background: var(--gray-100);

    &__controls {
      display: flex;
      justify-content: flex-end;
      flex: 1;

      &--split {
        justify-content: space-between;
      }
      &--middle {
        align-items: center;
      }

      & > * {
        margin-left: 0.5rem;
      }
    }

    &--white {
      background: var(--white);
    }

    &--no-padding {
      padding: 0;
    }
  }

  &--compact {
    padding: 0.5rem 0;

    .card-header,
    .card-body,
    .card-footer {
      padding: 0.5rem 1rem;
    }
  }

  &--modal {
    border-top: 0.25rem solid var(--primaryColor);

    &--success {
      border-top: 0.25rem solid var(--successColor);
    }

    &--warning {
      border-top: 0.25rem solid var(--warningColor);
    }

    &--danger {
      border-top: 0.25rem solid var(--dangerColor);
    }

    .card-header {
      padding: 1.5rem;
      border-bottom: 1px solid var(--gray-300);

      &__title {
        font-size: 1.125rem;
      }

      &__controls {
        button {
          color: var(--gray-600);
          width: 3rem;
          font-size: 1.5rem;
          height: 3rem;
          margin-top: -0.25rem;
          margin-right: -1rem;
        }
      }
    }

    .card-body {
      max-height: calc(100vh - 12.75rem);
      overflow-y: auto;
    }

    .card-footer {
      background-color: var(--gray-200);
      padding: 1rem 1.5rem;
    }
  }

  &--two-sections {
    display: flex;
    flex-flow: row nowrap;

    &--master {
      flex: 2;
      position: relative;
    }

    &--detail {
      flex: 1;
      border-left: 1px solid var(--gray-300);
      min-width: 20rem;
      position: relative;
    }

    &--fixed-footer {
      .card-body {
        padding-bottom: 4rem;
      }

      .card-footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
      }
    }
  }

  &--master-detail {
    display: flex;
    flex-flow: row nowrap;

    &--master {
      flex: 1;
      width: 27.5rem;
      position: relative;
      border-right: 1px solid var(--gray-300);
      @media screen and (max-width: 1280px) {
        width: 23rem;
      }
    }

    &--detail {
      flex: 3;
      width: 100%;
      height: 100%;
    }
  }
}
