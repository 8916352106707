//this file groups style info concerning overlays created with angular cdk overlay

.cdk-overlay-container {
  z-index: $cdk-z-index-overlay-container;
}

.modal {
  .modal-xxl {
    max-width: 80rem;
  }
}

.cdk-overlay-container:has(.cdk-overlay-container--hidden) {
  visibility: hidden;
}
