$types: primary, success, warning, danger, neutral;

@each $type in $types {
  .link-#{$type} {
    cursor: pointer;
    color: var(--#{$type}Color) !important;

    &:not(.btn-link) {
      height: 1rem;
      line-height: 1;

      > *:not(:last-child) {
        margin-right: 0.25rem;
      }
    }

    &:not(:disabled):not(.disabled):focus {
      box-shadow: 0 0 0 3px var(--primaryColorAlt) !important;
      border-radius: 0.25rem;
    }

    &:not(:disabled):not(.disabled):hover {
      text-decoration: underline;
    }

    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):focus {
      color: var(--#{$type}ColorLight) !important;
    }

    &:not(:disabled):not(.disabled):active {
      color: var(--#{$type}ColorDark) !important;
    }

    &:disabled,
    &.disabled {
      color: var(--disabledColor) !important;
      cursor: not-allowed;
    }
  }
}

// Vooral gebruikt in navigatie en alerts.
// Heeft in tegenstelling tot andere links geen box-shadow of verschillende kleuren on hover etc nodig.
.link-subtle {
  color: var(--gray-600);

  &:hover {
    text-decoration: underline;
  }
}
