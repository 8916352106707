$controls_height: 4rem;

.quick-filter {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.flyout-filter {
  position: relative;

  &__body {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: calc(100% - #{$controls_height});
    padding: 1rem;
  }

  &__section {
    display: flex;
    flex-direction: column;
  }

  &__section-header {
    color: var(--gray-600);
    border-bottom: 0.125rem var(--gray-300) solid;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    margin-bottom: 1rem;
  }

  &__controls {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: $controls_height;
    padding: 1rem 1.5rem;
    background: var(--gray-100);
    border-top: 2px solid var(--gray-400);
  }

  .form-check {
    padding-left: 0;
  }
}
