.highlight-block {
  margin: 0 0 1rem;
  padding: 1rem;
  background-color: var(--white);
  border: 1px solid var(--gray-400);
  border-radius: 0.25rem;

  &--title {
    font-weight: 500;
    color: var(--gray-700);
  }
  &--primary {
    background-color: var(--primaryColorWashed);
    border: 1px solid var(--primaryColorWashed);
  }
  &--success {
    background-color: var(--successColorWashed);
    border: 1px solid var(--successColorWashed);
  }
  &--warning {
    background-color: var(--warningColorWashed);
    border: 1px solid var(--warningColorWashed);
  }
  &--danger {
    background-color: var(--dangerColorWashed);
    border: 1px solid var(--dangerColorWashed);
  }

  &--gray-100 {
    background-color: var(--gray-100);
    border: 1px solid var(--gray-100);
  }

  &__title {
    font-weight: 500;
    font-size: 0.875rem;
    margin: 0 0 1rem;
    color: var(--gray-700);
    &--collapse {
      margin: 0;
    }
  }
  &__content {
    > * {
      margin: 0 0 0.5rem;
      &:last-child {
        margin: 0;
      }
    }
  }
  &__footer {
    margin: 1rem -1rem -1rem;
    padding: 0.75rem 1rem;
    border-top: 0;
    background: var(--gray-100);
    display: flex;
    justify-content: flex-end;
    flex: 1;
    & > * {
      margin-left: 0.5rem;
    }
  }
  &--with-footer {
    padding: 0;
    padding-top: 1.5rem;
  }
  .highlight-footer {
    @extend .card-footer;

    &__controls {
      @extend .card-footer__controls;
    }
  }
}
