.collapse {
  &__toggle {
    outline: 0;
    cursor: pointer;
    user-select: none;

    // only show focus ring on tab navigation
    &:focus-visible {
      border-radius: 0.25rem;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  &__panel {
    overflow: hidden;
  }
}

:not(.nxh-card-component).card {
  .collapse__panel > .card-body:first-of-type {
    padding-top: 0;
  }
}

.highlight-block {
  .collapse__panel {
    margin: 0 -1rem;
    padding: 0 1rem;

    > *:first-child {
      margin-top: 1rem;
    }
    > *:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}
