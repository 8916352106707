@import '../config/custom-variables';

.theme-moapr {
  .bs-datepicker-head {
    background-color: var(--primaryColor);
  }
  .bs-datepicker-body table td span.selected,
  .bs-datepicker-body table td.selected span,
  .bs-datepicker-body table td span[class*='select-']:after,
  .bs-datepicker-body table td[class*='select-'] span:after {
    background-color: var(--primaryColor);
  }
  .bs-datepicker-body table td.week span {
    color: var(--primaryColor);
  }
  .bs-datepicker-body table td.active-week span:hover {
    cursor: pointer;
    background-color: var(--primaryColor);
    color: #fff;
    opacity: 0.5;
    transition: 0s;
  }
  .today {
    background-color: var(--gray-300);
    border-radius: 0.25rem;
  }
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 1500 !important;
  border-radius: 4px;
  &.bottom {
    margin-top: 0.5rem;
  }
  .bs-datepicker {
    box-shadow: $dropdown-box-shadow;
  }

  &.top {
    margin-bottom: 1rem;
  }
  .bs-datepicker-container {
    padding: 0;

    .bs-datepicker-head {
      background-color: var(--white);

      button {
        color: var(--gray-800);
        &:hover {
          border-radius: 4px;
          background-color: var(--primaryColorWashed);
        }

        &.previous,
        &.next {
          color: var(--gray-500);
          font-weight: 300;
        }
      }
    }

    .bs-datepicker-body {
      padding: 0;
      border: 0;
      table {
        padding: 0.5rem;

        th {
          font-size: 10px;
          color: var(--gray-500);
          font-weight: 400;
          text-align: center;
          padding: 0.5rem;
          text-transform: uppercase;
          border: none;
        }
        tr:hover {
          td {
            background-color: unset;
          }
        }
        td {
          color: var(--gray-800);
          border: 0;

          &:hover {
            border-radius: 4px;
          }

          &:first-child {
            padding-left: inherit;
          }

          &:last-child {
            padding-right: inherit;
          }

          span {
            margin: 2px;
          }
        }

        td.is-highlighted:not(.disabled):not(.selected) span,
        td span.is-highlighted:not(.disabled):not(.selected) {
          background-color: var(--primaryColorWashed);
          border-radius: 4px;
        }

        td span.selected,
        td.selected span,
        td span[class*='select-']:after,
        td[class*='select-'] span:after {
          background-color: var(--primaryColor);
          border-radius: 4px;
        }
      }
    }
  }
}
