.nxh-align-left {
  text-align: left;
}

.nxh-align-right {
  text-align: right;
}

.nxh-align-center {
  text-align: center;
}
