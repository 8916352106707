input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
  border-color: var(--dangerColor);
}

.ng-invalid.ng-touched > .number-field {
  .form-control {
    border-color: var(--dangerColor);
  }
}
.ng-invalid.ng-touched > .form-control {
  border-color: var(--dangerColor);
}

.ng-invalid.ng-touched:not(.highlight-block),
.has-error-message {
  border-color: var(--dangerColor);
}

.has-error-message > ng-select > .ng-select-container {
  border-color: var(--dangerColor);
}

.ng-invalid.ng-touched > ng-select > .ng-select-container {
  border-color: var(--dangerColor) !important;
}

ng-select.ng-invalid.ng-touched > .ng-select-container {
  border-color: var(--dangerColor) !important;
}

.has-warning-message {
  border-color: var(--warningColor);
}
