.header {
  margin-bottom: 1rem;
  display: flex;

  .header_container {
    display: inline-block;

    h1 {
      display: inline-block;
    }

    fa-icon {
      font-size: 1.25rem;
      color: var(--primaryColor);
      margin-right: 1rem;
    }
  }
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}
