.list-group {
  .list-group-item {
    cursor: pointer;
    text-transform: capitalize;
    outline: none;
    font-size: 0.875rem;

    &.active {
      font-weight: 500;
      border-left: 0.25rem solid var(--primaryColor);
      padding-left: 1rem - 0.25rem;
      border-bottom: none;
      border-top: none;
    }
  }
}
