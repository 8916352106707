$lines: 5;

.maxlines {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

@for $i from 1 through $lines {
  .maxlines-#{$i} {
    -webkit-line-clamp: #{$i};
  }
}

@mixin full-height {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
