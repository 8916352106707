#root__error-page {
  display: none;
}

.error-page {
  .nh-page-wrapper {
    padding: 0;
  }

  .nh-page__content--body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .fatal-error-page__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15rem !important;

    .title {
      color: var(--gray-600);
      font-weight: bold;
      margin-bottom: 0.5rem;
      font-size: 2rem;
      line-height: 2.375rem;
    }

    .sub-title {
      font-size: 1.25rem;
      color: var(--gray-600);
      margin-bottom: 1.5rem;
      line-height: 1.75rem;
    }
  }
}
