.counter-bar-card {
  display: flex;
  background-color: var(--cardBGColor);
  border: 1px solid var(--gray-400);
  border-radius: 4px;

  &__side-bar {
    width: 1.5rem;
    background-color: var(--gray-100);
    border-right: 1px solid var(--gray-300);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
  }
}
