@media print {
  html,
  body {
    overflow-x: visible !important;
    height: auto !important;
    background-color: white;
  }

  nxh-navbar,
  nxh-patient-nav,
  nxh-expandable-side-menu,
  nxh-sidebar,
  nxh-tabnav {
    display: none !important;
  }

  ngb-tooltip-window {
    display: none !important;
  }

  .page-container {
    background-color: white;
    top: 0 !important;
    position: static !important;
  }

  .patient-page__container.mud-open {
    width: auto !important;
  }

  .nh-page {
    &__header {
      * {
        display: none;
      }

      &--title,
      &--title h1 {
        display: none;
      }
    }
  }

  .quick-filter {
    display: none !important;
  }

  // todo remove this when all pages are migrated to nh-page
  .page-header {
    * {
      display: none;
    }

    h1 {
      display: block;
    }
  }

  nxh-back-button {
    display: none;
  }

  @page {
    margin: 1cm;
  }

  // --- table ---

  table {
    .cdk-column-actions {
      display: none;
    }

    tr {
      th:first-child,
      td:first-child {
        padding-left: 0 !important;
      }
      th:last-child,
      td:last-child {
        padding-right: 0 !important;
      }
      td,
      th {
        font-size: 9pt !important;
      }
    }

    .sort-header__container {
      fa-icon {
        display: none;
      }
    }
  }

  // --- card ---

  .card {
    .card-footer {
      display: none;
    }
  }

  // --- utility ---
  .no-print {
    display: none !important;
  }

  .no-page-break {
    page-break-inside: avoid;
  }
}
