.tooltip {
  pointer-events: none;
  .arrow {
    display: none;
  }
  &.no-max-width {
    .tooltip-inner {
      max-width: initial;
    }
  }
  &-inner {
    text-align: left;
    max-width: 22rem;
    strong,
    b {
      font-size: 0.875rem;
    }
    &::first-letter {
      text-transform: capitalize;
    }
  }
}

@media screen and (max-width: 1280px) {
  .mobile-device {
    ngb-tooltip-window {
      display: none !important;
    }
  }
}
