.nh-bullet-list {
  list-style: none;
  margin-bottom: 0;
  > li {
    list-style: none;
    padding-left: 1rem;
    position: relative;
    line-height: 1.5;
    margin: 0 0 0.5rem;
    &:last-child {
      margin: 0;
    }
    &:before {
      content: '';
      width: calc(0.5rem - 1px);
      height: calc(0.5rem - 1px);
      border: 1px solid var(--gray-500);
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0.45rem;
    }
  }
}
