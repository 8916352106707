.tabnav {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
  .tabnav_items-container {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row;
    align-items: stretch;
    li {
      margin-right: 2rem;
      padding: 0;
      a {
        padding: 0;
        margin: 0;
        color: var(--gray-600);
        font-weight: 500;
        display: inline-block; // so as to be able to pass certain custom styling, e.g. no-capitalization
      }

      .active:not(.enable-workaround),
          // workaround - there some problem with the routerLinkActive directive with nested secondary routes?
        .workaround-active.enable-workaround {
        color: var(--primaryColor);
      }

      :hover {
        color: var(--primaryColor);
        background-color: transparent;
      }
    }
  }
  .tabnav__button-container {
    width: 100%;
    align-self: center;
    display: flex;
    justify-content: flex-end;
  }

  .tabnav_items-container a,
  .tabnav__button-container {
    padding-bottom: 0.75rem;
  }

  &.compact {
    align-items: flex-end;
    margin-bottom: 0;

    .tabnav__button-container {
      padding-bottom: 0;
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;
    }

    li {
      &:first-child {
        margin-left: 1.5rem;
      }

      a {
        padding-bottom: 0.5rem !important;

        &.active:not(.enable-workaround),
            // workaround - there some problem with the routerLinkActive directive with nested secondary routes?
          &.workaround-active.enable-workaround {
          border-bottom: 0.25rem solid var(--primaryColor);
        }
      }
    }
  }
}

hr {
  margin-top: -1px !important;
  margin-bottom: 0 !important;
  border-color: var(--gray-400);
}

.top-level {
  .tabnav {
    background-color: var(--gray-300);
    border-bottom: 1px solid var(--gray-400);
    margin: -1.5rem -1.5rem 1rem;
    padding: 1.5rem 1.5rem 0;
    .tabnav_items-container {
      li {
        white-space: nowrap;
        a {
          padding: 0 0 0.5rem;
          border-bottom: 2px solid transparent;
          &.active {
            border-bottom: 2px solid var(--primaryColor);
          }
        }
      }
    }
  }
}

nxh-sidebar {
  .tabnav {
    .tabnav_items-container {
      li {
        a {
          font-size: 1rem;
          font-weight: 400;
          color: var(--gray-700);
          padding-top: 0.5rem;
          &.active {
            font-weight: 500;
            color: var(--primaryColor);
          }
        }
      }
    }
  }
}
