.ag-theme-moapr {
  --ag-background-color: transparant;
  --ag-font-family: Roboto, sans-serif, Lato;
  --ag-font-size: 0.875rem;
  --ag-grid-size: 8px;
  --ag-icon-size: 16px;
  --ag-border-color: var(--gray-300);

  // header
  --ag-header-foreground-color: var(--gray-600);
  --ag-header-background-color: var(--bs-table-bg);
  // compromise between large screens and small screens (we cannot use rems here)
  --ag-header-height: calc(var(--ag-font-size) + var(--ag-grid-size) * 4.25);

  // row
  --ag-row-height: calc(var(--ag-font-size) + var(--ag-grid-size) * 3.5);
  --ag-row-hover-color: var(--primaryColorWashed);
  --ag-selected-row-background-color: var(--primaryColorWashed);
  --ag-odd-row-background-color: var(--gray-100);
  --ag-row-border-style: solid;
  --ag-row-border-width: thin;
  --ag-row-border-color: var(--gray-300);

  // resize handle
  --ag-header-column-resize-handle-color: var(--ag-row-border-color);
  --ag-header-column-resize-handle-display: block;
  --ag-header-column-resize-handle-width: 2px;
  --ag-header-column-resize-handle-height: 40%;

  // other variables to play with, cf. https://www.ag-grid.com/angular-data-grid/global-style-customisation-variables/
  // --ag-row-border-style
  // --ag-row-border-width
  // --ag-row-border-color
  // --ag-header-height
  // --ag-data-color
  // --ag-header-foreground-color
  // --ag-header-background-color
  // --ag-disabled-foreground-color
  // --ag-borders: 5px solid;
  // --ag-borders-critical: solid thin;
  // --ag-borders-secondary
  // --ag-secondary-border-color
  // --ag-grid-size
  // --ag-widget-container-horizontal-padding
  // --ag-widget-container-vertical-padding
  // --ag-widget-horizontal-spacing
  // --ag-widget-vertical-spacing
  // --ag-cell-horizontal-padding
  // --ag-cell-widget-spacing
  // --ag-row-height
  // --ag-header-height
  // --ag-list-item-height
  // --ag-header-column-separator-display
  // --ag-header-column-separator-height: 100%
  // --ag-header-column-separator-width
  // --ag-header-column-separator-color
  // --ag-header-column-resize-handle-color: var(--ag-secondary-border-color);
  // --ag-font-family
  // --ag-font-size:

  .ag-root-wrapper {
    border: none;
  }

  .ag-header-row {
    font-weight: 500;
  }

  .ag-row {
    line-height: var(--bs-body-line-height);
  }

  .ag-row-selected {
    .ag-cell:first-child {
      border-left: 3px solid var(--primaryColor);
      padding-left: calc(1.5rem - 2px);
    }
    .ag-cell {
      color: var(--gray-800);
    }
  }

  .ag-cell {
    display: flex;
    align-items: center;

    // clientSide wraps the text, while in infinite scroll mode everything is layed out on 1 line, demanding below rules
    &:not(.ag-cell-wrap-text) {
      .ag-cell-wrapper {
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    }

    &.ag-cell-wrap-text {
      white-space: normal;
      // todo Check, break-all is default
      word-break: break-word;
    }
  }

  .ag-column-first.nxh-loading-column {
    .ag-cell-wrapper {
      overflow-x: visible !important;
    }
  }
}
